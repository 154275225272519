import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";

export interface INotification {
  type: string;
  message: string;
  visibility: boolean;
}

@Module({ dynamic: true, store, name: "notification", namespaced: true })
class NotificationStateModule extends VuexModule implements INotification {
  type = "info";
  message = "";
  visibility = false;

  @Mutation
  setMessage(notify: { type: string; message: string }): void {
    this.type = notify.type;
    this.message = notify.message;
    this.visibility = true;
  }
  @Mutation
  clearMessage(): void {
    this.type = "info";
    this.message = "";
    this.visibility = false;
  }

  @Action({ rawError: true })
  notifyInfo(message: string): void {
    this.setMessage({ type: "info", message: message });
    setTimeout(() => {
      this.clearMessage();
    }, 6000);
  }
  @Action({ rawError: true })
  notifySuccess(message: string): void {
    this.setMessage({ type: "success", message: message });
    setTimeout(() => {
      this.clearMessage();
    }, 6000);
  }
  @Action({ rawError: true })
  notifyWarning(message: string): void {
    this.setMessage({ type: "warning", message: message });
    setTimeout(() => {
      this.clearMessage();
    }, 6000);
  }
  @Action({ rawError: true })
  notifyError(message: string): void {
    this.setMessage({ type: "error", message: message });
    setTimeout(() => {
      this.clearMessage();
    }, 6000);
  }
  @Action({ rawError: true })
  clearNotify(): void {
    setTimeout(() => {
      this.clearMessage();
    }, 6000);
  }
}

export const NotificationState = getModule(NotificationStateModule);
