








import SettingPageTemplate from "../../templates/SettingPageTemplate.vue";

export default {
  components: {
    SettingPageTemplate,
  },
};
